// react
import React from "react";

/**
 * Footer
 */

export const Footer = (props: any) => {

	return (
		<div id="Footer" className="footer">
			© 2022 Blunovus Care Inc.
			{props.children}
		</div>
	);
}

