// react
import React from "react";
import {inject, observer} from "mobx-react";
import { AiOutlineReload } from "react-icons/ai";

// local
import {
	Footer,
	Header,
	Loading
} from "../components";
import {
	PageProps,
	withNavigation,
	getDaysInMonth, KeyValuePair, Client,
	Resource, ResourceGroup, ResourceSummary
} from "../types";
import {Button, Dropdown, Table} from "react-bootstrap";
import {NavigateFunction} from "react-router/lib/hooks";

/**
 * Support Header
 */

interface SubheaderProps {
	navigate: NavigateFunction,
	client: Client,
	date: Date
	onChangeClient: (clientId: string) => void,
	onChangeDate: (year: number, month: number) => void,
	onRefreshList: () => void
}

const Subheader = (props: SubheaderProps) => {

	// setup
	const {client, onRefreshList} = props;

	// dropdown clients
	const eClients = client.clients.map(item =>
		<Dropdown.Item id={item.code} key={item.code} eventKey={item.code}>
			{item.name}
		</Dropdown.Item>);

	const onChangeClient = async (eventKey: any) => {
		// setup
		const clientId = eventKey;
		const {client} = props;

		// Update the settings.
		await client.setClient(clientId);
		props.onChangeClient(eventKey);
	}

	function onReports() {
		// setup
		const {navigate} = props;

		// Go to the Courses page.
		navigate("/reports");
	}

	return (
		<div className="subheader">

			<Dropdown onSelect={onChangeClient} className="padding">
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					{client ? client.name : "Choose a client"}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{eClients}
				</Dropdown.Menu>
			</Dropdown>

			<div className="navigate-sub">
				<Button variant="forgot" size="sm" onClick={onReports}>
					Back to Reporting
				</Button>
			</div>

			<Button variant="forgot" className="reload" size="sm" onClick={onRefreshList}>
				<AiOutlineReload size={20} color={'#9b61ff'} />
			</Button>

		</div>
	);
}

/**
 * SitesPage
 */

interface SitesProps extends PageProps {
}
interface SitesState {
	startDate: Date,
	endDate: Date
}

@inject("system")
@inject("user")
@inject("client")
@observer
class SitesPageComponent extends React.Component<SitesProps, SitesState> {

	constructor(props: SitesProps) {
		super(props);

		// Determine the default start/end date (this month).
		const date = new Date();
		const year = date.getFullYear();
		const month = date.getMonth() - 1;
		const startDate = new Date(year, month, 1, 0, 0, 0);
		const endDate = new Date(year, month, getDaysInMonth(month, year), 23, 59, 59);

		this.state = {
			startDate,
			endDate
		}

		// Register the callbacks.
		this.onChangeClient = this.onChangeClient.bind(this);
		this.onChangeDate = this.onChangeDate.bind(this);
		this.onGoBack = this.onGoBack.bind(this);
		this.onLogout = this.onLogout.bind(this);
		this.onRefreshList = this.onRefreshList.bind(this);
	}

	onChangeClient(clientId: string) {
	}

	onChangeDate(year: number, month: number) {
		// Determine the new start/end dates.
		const startDate = new Date(year, month, 1, 0, 0, 0);
		const endDate = new Date(year, month, getDaysInMonth(month, year), 23, 59, 59);

		// Retrieve the new information.
		this.setState({startDate, endDate});
	}

	onGoBack() {
		// setup
		const {navigate} = this.props;
		navigate('/reports');
	}

	onLogout() {
		// setup
		const {navigate, user} = this.props;

		// Logout and go to the login page.
		user.logout();
		navigate("/login");
	}

	onRefreshList() {
	}

	render() {
		// setup
		const {navigate, client} = this.props;
		const {startDate} = this.state;
		const {stats2, resources2} = client;
		const month = startDate.getMonth();

		// render
		return (
			<div className="page">
				<Header client={client}>
					<Button id="Logout" variant="primary" onClick={this.onLogout}>Logout</Button>
				</Header>
				<Subheader
					client={client}
					navigate={navigate}
					date={startDate}
					onChangeClient={this.onChangeClient}
					onChangeDate={this.onChangeDate}
					onRefreshList={this.onRefreshList}
				/>

				{ resources2.length ? this.renderBody(stats2, resources2, resources2[month]) : <Loading/>}

				<Footer/>
			</div>
		);
	}

	renderBody(summary: ResourceSummary, resources: ResourceGroup[], month: ResourceGroup) {
		return (
			<div className="body">
				<div className='scroll-y'>
					{ this.renderSummary(summary, resources) }
					{ this.renderTable(month.care, 'Care Center Engagements') }
					{ this.renderTable(month.site, 'Web Site Visits') }
					{ this.renderTable(month.page, 'Page Visits') }
					{ this.renderTable(month.course, 'Courses Viewed') }
					{ this.renderTable(month.post, 'Blog Posts Viewed') }
					{ this.renderTable(month.story, 'Stories Viewed') }
					<hr/>
				</div>
			</div>
		);
	}

	renderTable(resources: Resource[], title: string) {
		// setup
		let engageTotal = 0;
		let timeTotal = 0;
		const body = resources.map(item => {
			engageTotal += item.engagements;
			timeTotal += item.engagements * item.ave_time;
			const minutes = Math.round(item.ave_time / 60);
			const seconds = item.ave_time % 60;
			const timestamp = (seconds < 10) ? `${minutes}:0${seconds}` :  `${minutes}:${seconds}`;
			const category = item.activity_category.replace("_", " ");
			return (
				<tr>
					<td className="category">{category}</td>
					<td className="description">{item.activity_title}</td>
					<td className="value">{item.engagements}</td>
					<td className="value">{timestamp}</td>
				</tr>
			);
		});
		const summary = body.length ?
			<td></td> :
			<td className="summary warning">No Activity</td>;

		// Get the final tallies.
		if (engageTotal) {
			timeTotal = Math.round(timeTotal / engageTotal);
		}
		const minutes = Math.round(timeTotal / 60);
		const seconds = timeTotal % 60;
		const timestamp = (seconds < 10) ? `${minutes}:0${seconds}` :  `${minutes}:${seconds}`;

		// render
		return (
			<details open>
				<summary>{title}</summary>
				<Table id={`${title}-Table`} className="fixed" striped bordered>
					<thead>
					<th className="category">Category</th>
					<th className="description">Description</th>
					<th className="value">Engagements</th>
					<th className="value">Average Time</th>
					</thead>
					<tbody>
					{body}
					</tbody>
					<tfoot>
					<tr>
						<td className="category"></td>
						{summary}
						<td className="engagement">{engageTotal}</td>
						<td className="time">{timestamp}</td>
					</tr>
					</tfoot>
				</Table>
			</details>
		);
	}

	renderSummary(summary: ResourceSummary, resources: ResourceGroup[]) {
		// setup
		const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		const {startDate} = this.state;
		const monthId = startDate.getMonth();
		const yearId = startDate.getFullYear();

		let head = [ <th className="category">Category</th> ];
		let row = {
			care: [ <td className="category">Care Engagements</td> ],
			app: [ <td className="category">App Downloads</td> ],
			site: [ <td className="category">Web Site Visits</td> ],
			training: [ <td className="category">Live Training Engagements</td> ],
			pages: [ <td className="category">Page Visits</td> ],
			courses: [ <td className="category">Courses Viewed</td> ],
			posts: [ <td className="category">Blog Posts Viewed</td> ],
			stories: [ <td className="category">Stories Viewed</td> ],
		};
		let total = {
			care: 0,
			app: 0,
			training: 0,
			pages: 0,
			courses: 0,
			posts: 0,
			stories: 0,
			site: 0
		};
		let foot = [ <td></td> ];

		for (let i = 0; i < resources.length; i++) {
			// setup
			const month = resources[i];
			const className = (i === monthId) ? 'highlight' : '';

			head.push(<th className={"month selectable " + className} onClick={() => {this.onChangeDate(yearId, i)}}>
				{months[i]}
			</th>);

			let countApps = 0;
			row.app.push(<td className={className}>{countApps}</td>);
			total.app += countApps;

			let countSites = 0;
			month.site.forEach(item => {countSites += item.engagements});
			row.site.push(<td className={className}>{countSites}</td>);
			total.site += countSites;

			let countCare = 0;
			month.care.forEach(item => {countCare += item.engagements});
			row.care.push(<td className={className}>{countCare}</td>);
			total.care += countCare;

			let countTraining = 0;
			month.training.forEach(item => {countTraining += item.engagements});
			row.training.push(<td className={className}>{countTraining}</td>);
			total.care += countTraining;

			let countPages = 0;
			month.page.forEach(item => {countPages += item.engagements});
			row.pages.push(<td className={className}>{countPages}</td>);
			total.pages += countPages;

			let countCourses = 0;
			month.course.forEach(item => {countCourses += item.engagements});
			row.courses.push(<td className={className}>{countCourses}</td>);
			total.courses += countCourses;

			let countPosts = 0;
			month.post.forEach(item => {countPosts += item.engagements});
			row.posts.push(<td className={className}>{countPosts}</td>);
			total.posts += countPosts;

			let countStories = 0;
			month.story.forEach(item => {countStories += item.engagements});
			row.stories.push(<td className={className}>{countStories}</td>);
			total.stories += countStories;

			foot.push(<td className={"selectable " + className} onClick={() => {this.onChangeDate(yearId, i)}}>
				{countPages + countCourses + countPosts + countStories}
			</td>);
		}

		head.push(<th className="month">YTD Total</th>);
		row.care.push(<td className="total">{summary.care}</td>);
		row.app.push(<td className="total">{summary.app}</td>);
		row.training.push(<td className="total">{summary.site}</td>);
		row.site.push(<td className="total">{summary.site}</td>);
		row.pages.push(<td className="total">{summary.page}</td>);
		row.courses.push(<td className="total">{summary.course}</td>);
		row.posts.push(<td className="total">{summary.post}</td>);
		row.stories.push(<td className="total">{summary.story}</td>);
		foot.push(<td></td>);

		// render
		return (
			<details open>
				<summary>Summary</summary>
				<Table id={`Summary-Table`} className="fixed" striped bordered>
					<thead>{head}</thead>
					<tbody>
						<tr>{row.care}</tr>
						<tr>{row.app}</tr>
						<tr>{row.site}</tr>
						<tr>{row.training}</tr>
						<tr>{row.pages}</tr>
						<tr>{row.courses}</tr>
						<tr>{row.posts}</tr>
						<tr>{row.stories}</tr>
					</tbody>
					<tfoot>
						<tr>{foot}</tr>
					</tfoot>
				</Table>
			</details>
		);
	}

}

export const SitesPage = withNavigation(SitesPageComponent);
