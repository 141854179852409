// react
import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getAuth} from "firebase/auth";

export const fbConfig = {
	apiKey: "AIzaSyBOsNZas9voG4m6pWwN0BuFkBluUEE1eAo",
	projectId: "reportingblunovus",
	databaseURL: "https://reportingblunovus.firebaseio.com",
	authDomain: "reportingblunovus.firebaseapp.com",
	storageBucket: "reportingblunovus.appspot.com",
	messagingSenderId: "349160500131",
	appId: "1:349160500131:web:5e59395e37dd3a80cc0816",
	measurementId: "G-7N79MD31FD"
}

export const fbApp = initializeApp(fbConfig);
export const fbDB = getFirestore(fbApp);
export const fbAuth = getAuth(fbApp);
