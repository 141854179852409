// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {Resource} from "../types";

/**
 * Course Categories
 */

interface CategoriesProps {
	categories: Resource[],
}
interface CategoriesState {
}

export class CourseCategories extends React.Component<CategoriesProps, CategoriesState> {

	render() {
		// setup
		let total = 0;
		const {categories} = this.props;

		// Construct the list.
		const body = categories.length ?
			categories.map(item => {
				total += item.engagements;
				return (<tr key={'course'+total}>
					<td className="description">{item.activity_title}</td>
					<td className="value">{item.engagements}</td>
				</tr>);
			}) :
			<tr id={'empty'} key={'empty'}>
				<td className="description warning" colSpan={2}>No Activity</td>
			</tr>;

		// render
		return (
			<div id="CourseCategories" key="CourseCategories" className="section">
				<h4 className="title">Course Categories</h4>
				<Table id="CourseCategories-Table" key="CourseCategories-Table" striped bordered>
					<tbody >{body}</tbody>
					<tfoot><tr><td className="description">total</td><td className="value">{total}</td></tr></tfoot>
				</Table>
			</div>
		)
	}

}
