// react
import React from "react";
import {Toast} from "react-bootstrap";
import {BeatLoader} from "react-spinners";

/**
 * Footer
 */

export const PopupMessage = (props: any) => {

	return (
		<Toast
			className="toast"
			show={props.show}
			onClose={props.onClose}
		>
			<Toast.Header>
				<strong className="me-auto">{props.title}</strong>
			</Toast.Header>
			<Toast.Body>
				{(props.body === 'Loading') ?
					<div>
						<BeatLoader size={15} color={"#cccccc"} />
					</div> :
					<div>{props.body}</div>
				}
				</Toast.Body>
		</Toast>
	);
}
