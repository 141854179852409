// react
import React from "react";
import {inject, observer} from "mobx-react"

// local
import {
	Footer,
	Header,
} from "../components";
import {
	defaultUser,
	PageProps,
	withNavigation
} from "../types";
import {
	ForgotPasswordForm,
	LoginForm
} from "../forms";

/**
 * LoginPage
 */

interface LoginProps extends PageProps {
}
interface LoginState {
	passVisible: boolean,
	forgotPassword: boolean
}

@inject("system")
@inject("user")
@inject("client")
@observer
class LoginPageComponent extends React.Component<LoginProps, LoginState> {

	constructor(props: LoginProps) {
		super(props);

		this.state = {
			passVisible: false,
			forgotPassword: false
		}

		this.onSubmitForgotPassword = this.onSubmitForgotPassword.bind(this);
		this.onSubmitLogin = this.onSubmitLogin.bind(this);
	}

	onSubmitForgotPassword(action: string | null) {
		this.setState({forgotPassword: false});
	}

	onSubmitLogin(action: string | null) {
		// setup
		const {navigate} = this.props;

		// Navigate to login, if successful.
		if (action === 'reports') {
			navigate('/reports');
		} else if (action === 'forgotpassword') {
			this.setState({forgotPassword: true});
		}
	}

	render() {
		// setup
		const {system, user, client, user: {errorLevel, errorMessage}} = this.props;
		const {forgotPassword} = this.state;

		// render
		return (
			<div className="page">
				<Header/>
				{forgotPassword
					? <ForgotPasswordForm user={user} submit={this.onSubmitForgotPassword}/>
					: <LoginForm
						system={system}
						user={user}
						client={client}
						submit={this.onSubmitLogin}
						errorLevel={errorLevel}
						errorMessage={errorMessage}
					/>
				}
				<Footer/>
			</div>
		);
	}

}

export const LoginPage = withNavigation(LoginPageComponent);
