// react
import React from "react";
import {BeatLoader} from "react-spinners";

/**
 * Loading
 */

export class Loading extends React.Component {

	render() {
		return (
			<div className="spinner">
				<BeatLoader size={30} color={"#cccccc"} />
			</div>
		);
	}

}
