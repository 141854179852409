// react
import React from "react";

/**
 * Header
 */

export const Header = (props: any) => {
	return (
		<div id="Header" className="header">
			<img className="left-logo" alt="Application Logo"
			     src="https://firebasestorage.googleapis.com/v0/b/reportingblunovus.appspot.com/o/logos%2FBluNovus-FullColor-RGB-sq.png?alt=media&amp;token=39d2d84c-c05f-465a-a781-7266687f734d"
			/>
			<h4 className="app-title">REPORTING</h4>
			{props.client && props.client.logo &&
				<img className="center-logo" alt="Client Logo"
				     src={props.client.logo}
				/>
			}
			{props.children}
		</div>
	);
}
