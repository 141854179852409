// react
import React from "react";
import {Button, Dropdown} from "react-bootstrap";
import {inject, observer} from "mobx-react";

// local
import {
	Client,
	KeyValuePair,
	PageProps, System,
	withNavigation,
} from "../types";
import {
	AnnualEngagements,
	CareUtilization,
	CompanyInsights,
	CourseCategories,
	CourseViews,
	Footer,
	Loading,
	Header,
	SiteVisits,
	SummaryStats
} from "../components";
import {NavigateFunction} from "react-router/lib/hooks";

/**
 * Support Header
 */

interface SubheaderProps {
	navigate: NavigateFunction,
	client: Client
}

const Subheader = (props: SubheaderProps) => {

	// setup
	const {client} = props;

	const eClients = client.clients.map(item => <Dropdown.Item id={item.code} key={item.code} eventKey={item.code}>
			{item.name}
		</Dropdown.Item>);

	const onChangeClient = async (eventKey: any) => {
		// setup
		const clientId = eventKey;
		const {client} = props;

		// Update the settings.
		await client.setClient(clientId);
	}

	return (
		<div className="subheader">

			<Dropdown onSelect={onChangeClient}>
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					{client ? client.name : "Choose a client"}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{eClients}
				</Dropdown.Menu>
			</Dropdown>

		</div>
	);
}

/**
 * ReportsPage
 */

interface ReportsProps extends PageProps {
}
interface ReportsState {
	loaded: boolean,
	chartId: string,
	chartName: string
}

@inject("system")
@inject("user")
@inject("client")
@observer
export class ReportsPageComponent extends React.Component<ReportsProps, ReportsState> {

	constructor(props: ReportsProps) {
		super(props);

		this.state = {
			loaded: false,
			chartId: 'utilization',
			chartName: 'Care Center Utilization'
		}

		this.onChangeClient = this.onChangeClient.bind(this);
		this.onLogout = this.onLogout.bind(this);
		this.onNavigateSites = this.onNavigateSites.bind(this);
		this.onNavigateEngagements = this.onNavigateEngagements.bind(this);
		this.onSelectChart = this.onSelectChart.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {client, user, navigate} = this.props;

		// Get the client list and possibly client info.
		if (!client.id) {
			user.logout();
			navigate("/login");
		}

		// Update the state.
		this.setState({loaded: true});
	}

	onChangeClient = async (eventKey: any) => {
		// setup
		const clientId = eventKey;
		const {client} = this.props;

		// Update the settings.
		await client.setClient(clientId);
	}

	onNavigateSites() {
		// setup
		const {navigate} = this.props;

		// Go to the engagements page.
		navigate("/sites");
	}

	onNavigateEngagements() {
		// setup
		const {navigate} = this.props;

		// Go to the engagements page.
		navigate("/engagements");
	}

	onLogout() {
		// setup
		const {navigate, user} = this.props;

		// Logout and go to the login page.
		user.logout();
		navigate("/login");
	}

	onSelectChart(chartId: string, chartName: string) {
		this.setState({chartId, chartName});
	}

	render() {
		// setup
		const {client, user, navigate} = this.props;
		const {loaded} = this.state;
		if (!loaded) {
			return <Loading/>
		}

		// render
		return (
			<div className="page">
				<Header client={client}>
					<Button id="Logout" variant="primary" onClick={this.onLogout}>Logout</Button>
				</Header>

				{user.admin && <Subheader
					client={client}
					navigate={navigate}
				/>}

				<div className="body">
					<div className="scroll-y">
						{this.renderSummary(user.admin)}
						<hr/>
						{this.renderCourses(user.admin)}
						<hr/>
						{this.renderInsights()}
						<hr/>
					</div>
				</div>

				<Footer/>
			</div>
		);
	}

	renderCourses(isAdmin: boolean) {
		// setup
		const {client} = this.props;
		const pages = client.getGroupSummary('page');
		const courses = client.getGroupSummary('course');
		const categories = client.getGroupSummary('course-categories');

		// render
		return (
			<details open>
				<summary>Site Information</summary>
				{isAdmin && <div className="navigate-main">
					<Button variant="forgot" size="sm" onClick={this.onNavigateSites}>
						Site Analytics
					</Button>
				</div>}

				<div className="row">
					<div className="column">
						<SiteVisits visits={pages}/>
						<CourseCategories categories={categories}/>
					</div>
					<div className="column">
						<CourseViews views={courses}/>
					</div>
				</div>
			</details>
		)
	}

	renderInsights() {
		// setup
		const {client, system} = this.props;
		const cInsights = client.getCareInsights();
		const sInsights = system.getCareInsights();

		// render
		return (
			<details open>
				<summary>Care Center Insights</summary>
				<CompanyInsights
					clientName={client.name}
					clientReason={cInsights.reason}
					clientWho={cInsights.who}
					allReason={sInsights.reason}
					allWho={sInsights.who}/>
			</details>
		)
	}

	renderSummary(isAdmin: boolean) {
		// setup
		const {client} = this.props;
		const {summary, getMonthlyTotals} = client;
		const {chartId, chartName} = this.state;
		const totals2 = getMonthlyTotals(chartId, true);
		const totals1 = getMonthlyTotals(chartId, false);
		const ctlMonths = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec' ];

		// render
		return (
			<details open>
				<summary>Engagement Summary</summary>
				{isAdmin && <div className="navigate-main">
					<Button variant="forgot" size="sm" onClick={this.onNavigateEngagements}>
						Engagement Details
					</Button>
				</div>}

				<div className="row">
					<div className="column">
						<SummaryStats
							summary={summary}
							chartId={chartId}
							chartName={chartName}
							onSelectChart={this.onSelectChart}
							isAdmin={isAdmin}
						/>
					</div>
					<div className="column">
						{(chartId === 'utilization') ?
							<CareUtilization
								chartName={chartName}
								totals2={totals2}
								totals1={totals1}
								ctlMonths={ctlMonths}
							/> :
							<AnnualEngagements
								chartId={chartId}
								chartName={chartName}
								totals2={totals2}
								ctlMonths={ctlMonths}
							/>
						}
					</div>
				</div>
			</details>
		)
	}

}

export const ReportsPage = withNavigation(ReportsPageComponent);
