// react
import {signInWithEmailAndPassword, sendPasswordResetEmail} from "firebase/auth";
import {flow, types, Instance, applySnapshot} from "mobx-state-tree"
import {collection, documentId, getDocs, query, where} from "firebase/firestore";
import axios from "axios";

// local
import {fbAuth, fbDB} from "./firebase";
import {
	summaryStats
} from "../components";
import {API_URL, API_LCT_KEY, API_IOMAD_KEY, fridaysInMonth, ROOT_COMPANY_ID} from "./variables";

export const webservice = axios.create({
	baseURL: API_URL,
	url: '/webservice/rest/server.php',
	method: 'POST',
	withCredentials: false,
});

/**
 * General Resources
 */

const KeyValueInfo = types.model("KeyValuePair", {
	id: types.string,
	name: types.string,
	value: types.number,
	color: types.maybeNull(types.string)
});
export type KeyValuePair = Instance<typeof KeyValueInfo>;

const MdlClientInfo = types.model("ClientItem", {
	id: types.number,
	name: types.string,
	shortname: types.string,
	code: types.string,
	country: types.string,
	locale: types.string,
	logo: types.maybeNull(types.string)
});
export type MdlClient = Instance<typeof MdlClientInfo>;

/**
 * Moodle resources
 */

export const MdlResource = types.model("MdlResource", {
	activity_id: types.number,
	activity_type: types.string,
	activity_category: types.string,
	activity_name: types.string,
	activity_title: types.string,
	engagements: types.number,
	ave_time: types.number
});
export type Resource = Instance<typeof MdlResource>;

export const MdlResourceGroup = types.model("MdlResourceGroup", {
	care: types.array(MdlResource),
	hope: types.array(MdlResource),
	training: types.array(MdlResource),
	page: types.array(MdlResource),
	course: types.array(MdlResource),
	post: types.array(MdlResource),
	story: types.array(MdlResource),
	app: types.array(MdlResource),
	site: types.array(MdlResource),
});
export type ResourceGroup = Instance<typeof MdlResourceGroup>;

export const MdlResourceSummary = types.model("MdlResourceSummary", {
	utilization: types.number,
	census: types.number,
	care: types.number,
	hope: types.number,
	training: types.number,
	page: types.number,
	course: types.number,
	post: types.number,
	story: types.number,
	app: types.number,
	site: types.number,
})

export type ResourceSummary = Instance<typeof MdlResourceSummary>;

/**
 * UserInfo - This structure is used for mobx storage efficiency.
 */

export const UserInfo = types.model("User", {
	id: types.maybeNull(types.string),
	email: types.maybeNull(types.string),
	clientId: types.maybeNull(types.string),
	admin: types.boolean,
	errorLevel: types.maybeNull(types.string),
	errorMessage: types.maybeNull(types.string),
}).actions(self => {

	const authenticate = flow(function* authenticate(email: string, password: string) {
		// admin user patterns
		const allowAdmin = ['@lecticon.com', '@blunovus.com'];

		// See if we authenticate.
		yield signInWithEmailAndPassword(fbAuth, email, password)
			.then((userCredential) => {
				const user = userCredential.user;
				const admin = !!allowAdmin.find(a => email.includes(a));
				applySnapshot(self, {...self, id: user.uid, email: user.email, errorLevel: null, errorMessage: null, admin});
			})
			.catch((err) => {
				// Catch the error.
				const errorMessage = "Invalid email or password";
				applySnapshot(self, {id: null, email: null, clientId: null, admin: false, errorLevel: "danger", errorMessage});
			});

		// Get the user (default client) information.
		if (self.admin) {
			applySnapshot(self, {...self, clientId: 'blunovus'});
		} else if (self.id) {
			// success
			const docId = documentId();
			const dbQuery = query(collection(fbDB, 'users'), where(docId, "==", self.id));
			const dbSnapshot = yield getDocs(dbQuery);
			const data = dbSnapshot.docs[0].data();
			applySnapshot(self, {...self, clientId: data.clientId});
		}

		// Return the results.
		return !!self.id;
	})

	const forgotPassword = flow(function* forgotPassword(email: string) {
		yield sendPasswordResetEmail(fbAuth, email)
			.then((userCredential) => {
				applySnapshot(self, {...self, errorLevel: "warning", errorMessage: "Please check your email."});
			})
			.catch((err) => {
				const errorMessage = `"${email}" is not in our records. Please try again.`;
				applySnapshot(self, {...self, errorLevel: "warning", errorMessage});
			});
	})

	function logout() {
		applySnapshot(self, defaultUser)
	}

	return {
		authenticate,
		forgotPassword,
		logout
	}
});
export type User = Instance<typeof UserInfo>;

export const defaultUser = UserInfo.create({
	id: null,
	email: null,
	clientId: null,
	admin: false,
	errorLevel: null,
	errorMessage: null
});

/**
 * ClientInfo - This structure is used for mobx storage efficiency.
 */
export const ClientInfo = types.model("Client", {
	initialized: types.boolean,
	loading: types.boolean,
	clients: types.array(MdlClientInfo),
	id: types.number,
	name: types.maybeNull(types.string),
	summary: types.array(KeyValueInfo),
	logo: types.maybeNull(types.string),
	stats1: MdlResourceSummary,
	resources1: types.array(MdlResourceGroup),
	stats2: MdlResourceSummary,
	resources2: types.array(MdlResourceGroup)
}).actions(self => {

	function clearStats() {
		applySnapshot(self, defaultClient);
	}

	const fetchAnalytics = async (companyid: string, year: number) => {
		// setup
		applySnapshot(self, {...self, loading: true}); // for protection

		const options = new FormData();
		options.append('wstoken', API_LCT_KEY);
		options.append('wsfunction', 'bn_get_analytics');
		options.append('moodlewsrestformat', 'json');
		options.append('companyid', companyid);
		options.append('year', year.toString());

		// Make the request.
		const results = await webservice.post('/webservice/rest/server.php', options);

		// Update the states.
		if (results && results.status === 200) {
			return {
				stats: results.data.summary,
				resources: results.data.resources
			};
		}
		return {
			stats: {utilization: 0, census: 0, care: 0, page: 0, course: 0, post: 0, story: 0, app: 0, site: 0},
			resources: []
		};
	}

	const fetchClients = flow(function* fetchClients() {
		// setup
		const options = new FormData();
		options.append('wstoken', API_LCT_KEY);
		options.append('wsfunction', 'mdl_get_companies');
		options.append('moodlewsrestformat', 'json');
		options.append('rootid', ROOT_COMPANY_ID.toString());

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);

		// Update the client information.
		if (!results || results.status !== 200) {
			return [];
		}

		// clients
		return results.data;
	})

	const initialize = flow(function* initialize() {
		// Get the list of clients.
		if (self.initialized) {
			return;
		}
		const clients = yield fetchClients();

		// Update the states.
		applySnapshot(self, {...self, clients, initialized: true});
	})

	function mapKeyValues(mapping: any[], data: any, filter: boolean, percent: boolean, sort: boolean) {
		let results = [];
		for (let i = 0; i < mapping.length; i++) {
			const item = mapping[i];
			const value = data[item.id] ? data[item.id] : 0;
			if (!filter || value) {
				const name = percent ? `${item.name} ${value}%` : item.name;
				results.push({id: item.id, name, value, color: item.color});
			}
		}
		return sort
			? results.sort(function(a, b) {return (a.value > b.value) ? -1 : 1;})
			: results;
	}

	const setClient = flow(function* authenticate(clientId: string) {
		// setup
		const client = self.clients.find(client => clientId === client.code || clientId === client.name);
		if (!client) {
			return;
		}
		applySnapshot(self, {...self, loading: true}); // for protection

		// summary stats and engagements
		const date = new Date();
		const year = date.getFullYear();

		const year2 = yield fetchAnalytics(client.code, year);
		const year1 = yield fetchAnalytics(client.code, year-1);
		const summary = mapKeyValues(summaryStats, year2.stats, false, false, false);

		// Update the states.
		applySnapshot(self, {...self, id: client.id, name: client.name, summary, logo: client.logo,
			stats1: year1.stats, resources1: year1.resources, stats2: year2.stats, resources2: year2.resources,
			loading: false});
	})

	function strCamelize(srcString: string) {
		return srcString.replace('_', ' ').replace(/\w+/g, function(w) {
			return w[0].toUpperCase() + w.slice(1).toLowerCase();
		});
	}

	return {
		clearStats,
		fetchAnalytics,
		initialize,
		setClient,
		strCamelize
	}
}).views(self => ({

	findResourceByCategory(resources: Resource[], item: Resource) {
		let resource = resources.find((r: Resource) => r.activity_category === item.activity_category);
		if (resource) {
			resource.engagements += item.engagements;
		} else {
			const title = self.strCamelize(item.activity_category);
			resources.push({
				activity_id: item.activity_id,
				activity_type: item.activity_type,
				activity_category: item.activity_category,
				activity_name: item.activity_name,
				activity_title: title,
				engagements: item.engagements,
				ave_time: item.ave_time});
		}
	},

	findResourceByTitle(resources: Resource[], item: Resource) {
		let resource = resources.find((r: Resource) => r.activity_title === item.activity_title);
		if (resource) {
			resource.engagements += item.engagements;
		} else {
			resources.push({
				activity_id: item.activity_id,
				activity_type: item.activity_type,
				activity_category: item.activity_category,
				activity_name: item.activity_name,
				activity_title: item.activity_title,
				engagements: item.engagements,
				ave_time: item.ave_time});
		}
	},

	getCareInsights() {
		// setup
		let reasons: KeyValuePair[] = [
			{ id: "Crisis", name: 'Crisis', value: 0, color: "#D61C4E"},
			{ id: "Anxiety", name: 'Anxiety', value: 0, color: "#790252"},
			{ id: "Depression", name: 'Depression', value: 0, color: "#526185FF"},
			{ id: "Relationships", name: 'Relationships', value: 0, color: "#5BB318"},
			{ id: "Addiction", name: 'Addictions', value: 0, color: "#3D8361"},
			{ id: "Work Stress", name: 'Work Stress', value: 0, color: "#FF7F3F"},
			{ id: "Grief or Loss", name: 'Grief or Loss', value: 0, color: "#6B7EADFF"},
			{ id: "Referral/Support", name: 'Other', value: 0, color: "#AB9922FF"},
		];
		let reasonTotal = 0;
		let whos: KeyValuePair[] = [
			{ id: "Employee", name: 'Employee', value: 0, color: "#4D77FF"},
			{ id: "Loved Ones", name: 'Loved Ones', value: 0, color: "#56BBF1"}
		];
		let whoTotal = 0;
		if (!self.resources2.length) {
			return {
				reason: reasons,
				who: whos
			}
		}

		// Group and add.
		for (let i = 0; i < 12; i++) {
			const month = self.resources2[i] as ResourceGroup;
			for (let j = 0; j < month.care.length; j++) {
				const item = month.care[j];

				// reason
				let reason = reasons.find(r => item.activity_name === r.id);
				if (!reason) {
					reason = reasons[reasons.length-1]; // other
				}
				reason.value += item.engagements;
				reasonTotal += item.engagements;

				// who
				let who = whos.find(r => item.activity_category === r.id);
				if (!who) {
					who = whos[whos.length-1]; // other
				}
				who.value += item.engagements;
				whoTotal += item.engagements;
			}
		}

		// Now get the percentages.
		reasons.forEach(item => {
			item.value = reasonTotal ? Math.ceil(item.value * 1000 / reasonTotal) / 10 : 0;
			item.name = `${item.id}: ${item.value}%`;
		});
		whos.forEach(item => {
			item.value = whoTotal ? Math.ceil(item.value * 1000 / whoTotal) / 10 : 0;
			item.name = `${item.id}: ${item.value}%`;
		});

		// Return the totals.
		return {
			reason: reasons,
			who: whos
		}
	},

	getGroupSummary(id: string) {
		// setup (for closure)
		const findResourceByTitle = this.findResourceByTitle;
		const findResourceByCategory = this.findResourceByCategory;
		if (!self.resources2.length) {
			return [];
		}

		// Walk the resources to determine the month-to-month resources.
		let resources: Resource[] = [];
		for (let i = 0; i < 12; i++) {
			const month = self.resources2[i] as ResourceGroup;
			if (id === 'care' && month.care) {
				month.care.forEach(item => { findResourceByTitle(resources, item); });
			} else if (id === 'hope' && month.hope) {
				month.hope.forEach(item => { findResourceByTitle(resources, item); });
			} else if (id === 'training' && month.training) {
				month.training.forEach(item => { findResourceByTitle(resources, item); });
			} else if (id === 'page' && month.page) {
				month.page.forEach(item => { findResourceByTitle(resources, item); });
			} else if (id === 'course' && month.course) {
				month.course.forEach(item => { findResourceByTitle(resources, item); });
			} else if (id === 'post' && month.post) {
				month.post.forEach(item => { findResourceByTitle(resources, item); });
			} else if (id === 'story' && month.story) {
				month.story.forEach(item => { findResourceByTitle(resources, item); });
			} else if (id === 'app' && month.app) {
				month.app.forEach(item => { findResourceByTitle(resources, item); });
			} else if (id === 'site' && month.site) {
				month.site.forEach(item => { findResourceByTitle(resources, item); });
			} else if (id === 'course-categories' && month.course) {
				month.course.forEach(item => { findResourceByCategory(resources, item); });
			}
		}
		return resources;
	},

	getMonthlyTotals(id: string, currentYear: boolean) {
		// setup
		const census = currentYear ? self.stats2.census : self.stats1.census;
		const hope = currentYear ? self.stats2.hope : self.stats1.hope; // TEMPORARY
		const resources = currentYear ? self.resources2 : self.resources1;
		const yearId = currentYear ? (new Date()).getFullYear() : (new Date()).getFullYear()-1;
		const monthId = (new Date()).getMonth();
		if (!resources.length) {
			return [];
		}

		// Walk the resources to determine the month-to-month stats.
		let totals = [];
		for (let i = 0; i < 12; i++) {
			let count = 0;
			const month = resources[i] as ResourceGroup;
			if (id === 'utilization' && month.care) {
				month.care.forEach(item => {count += item.engagements});
				count = (count * 100 / census);
			} else if (id === 'census') {
				count = (i <= monthId) ? census : 0;
			} else if (id === 'care' && month.care) {
				month.care.forEach(item => {count += item.engagements});
			} else if (id === 'hope' && month.hope) {
				count = (i <= monthId) ? hope : 0;
			} else if (id === 'hope-messages' && month.hope) {
				count = (i <= monthId) ? hope * fridaysInMonth(i+1, yearId) : 0;
			} else if (id === 'training' && month.training) {
				month.training.forEach(item => {count += item.engagements});
			} else if (id === 'page' && month.page) {
				month.page.forEach(item => {count += item.engagements});
			} else if (id === 'course' && month.course) {
				month.course.forEach(item => {count += item.engagements});
			} else if (id === 'post' && month.post) {
				month.post.forEach(item => {count += item.engagements});
			} else if (id === 'story' && month.story) {
				month.story.forEach(item => {count += item.engagements});
			} else if (id === 'app' && month.app) {
				month.app.forEach(item => {count += item.engagements});
			} else if (id === 'site' && month.site) {
				month.site.forEach(item => {count += item.engagements});
			}
			totals.push(count);
		}
		return totals;
	}

}))
export type Client = Instance<typeof ClientInfo>;

export const defaultClient = ClientInfo.create({
	initialized: false,
	loading: false,
	id: 0,
	name: null,
	summary: [],
	logo: null,
	stats1: {utilization: 0, census: 0, care: 0, hope: 0, training: 0, page: 0, course: 0, post: 0, story: 0, app: 0, site: 0},
	resources1: [],
	stats2: {utilization: 0, census: 0, care: 0, hope: 0, training: 0, page: 0, course: 0, post: 0, story: 0, app: 0, site: 0},
	resources2: []
});

/**
 * SystemInfo - This structure is used for mobx storage efficiency.
 */
export const SystemInfo = types.model("System", {
	initialized: types.boolean,
	loading: types.boolean,
	stats1: MdlResourceSummary,
	resources1: types.array(MdlResource),
	stats2: MdlResourceSummary,
	resources2: types.array(MdlResource)
}).actions(self => {

	const fetchAnalytics = flow(function* fetchAnalytics(year: number) {
		// setup
		const options = new FormData();
		options.append('wstoken', API_LCT_KEY);
		options.append('wsfunction', 'bn_get_summary_analytics');
		options.append('moodlewsrestformat', 'json');
		options.append('year', year.toString());

		// Make the request.
		const results = yield webservice.post('/webservice/rest/server.php', options);

		// Update the states.
		if (results && results.status === 200) {
			return {
				stats: results.data.summary,
				resources: results.data.resources
			};
		}
		return {
			stats: {utilization: 0, census: 0, care: 0, page: 0, course: 0, post: 0, story: 0, app: 0, site: 0},
			resources: []
		}
	})

	const initialize = flow(function* initialize() {
		// setup
		if (self.initialized) {
			return;
		}
		const date = new Date();
		const year = date.getFullYear();
		applySnapshot(self, {...self, loading: true}); // for protection

		// summary stats and engagements
		const year2 = yield fetchAnalytics(year);

		applySnapshot(self, {...self, stats2: year2.stats, resources2: year2.resources, initialized: true, loading: false}); // for protection
	})

	return {
		initialize
	}

}).views(self => ({

	getCareInsights() {
		// setup
		let reasons: KeyValuePair[] = [
			{ id: "Crisis", name: 'Crisis', value: 0, color: "#D61C4E"},
			{ id: "Anxiety", name: 'Anxiety', value: 0, color: "#790252"},
			{ id: "Depression", name: 'Depression', value: 0, color: "#526185FF"},
			{ id: "Relationships", name: 'Relationships', value: 0, color: "#5BB318"},
			{ id: "Addiction", name: 'Addictions', value: 0, color: "#3D8361"},
			{ id: "Work Stress", name: 'Work Stress', value: 0, color: "#FF7F3F"},
			{ id: "Grief or Loss", name: 'Grief or Loss', value: 0, color: "#6B7EADFF"},
			{ id: "Referral/Support", name: 'Other', value: 0, color: "#AB9922FF"},
		];
		let reasonTotal = 0;
		let whos: KeyValuePair[] = [
			{ id: "Employee", name: 'Employee', value: 0, color: "#4D77FF"},
			{ id: "Loved Ones", name: 'Loved Ones', value: 0, color: "#56BBF1"}
		];
		let whoTotal = 0;

		// Group and add.
		const resources = self.resources2.filter(item => item.activity_type === 'care');
		resources.forEach((item: Resource) => {
			// reason
			let reason = reasons.find(r => item.activity_name === r.id);
			if (!reason) {
				reason = reasons[reasons.length-1]; // other
			}
			reason.value += item.engagements;
			reasonTotal += item.engagements;

			// who
			let who = whos.find(r => item.activity_category === r.id);
			if (!who) {
				who = whos[whos.length-1]; // other
			}
			who.value += item.engagements;
			whoTotal += item.engagements;
		});

		// Now get the percentages.
		reasons.forEach(item => {
			item.value = reasonTotal ? Math.ceil(item.value * 1000 / reasonTotal) / 10 : 0;
			item.name = `${item.id}: ${item.value}%`;
		});
		whos.forEach(item => {
			item.value = whoTotal ? Math.ceil(item.value * 1000 / whoTotal) / 10 : 0;
			item.name = `${item.id}: ${item.value}%`;
		});

		// Return the totals.
		return {
			reason: reasons,
			who: whos
		}
	}

}))

export type System = Instance<typeof SystemInfo>;

export const defaultSystem = SystemInfo.create({
	initialized: false,
	loading: false,
	stats1: {utilization: 0, census: 0, care: 0, hope: 0, training: 0, page: 0, course: 0, post: 0, story: 0, app: 0, site: 0},
	resources1: [],
	stats2: {utilization: 0, census: 0, care: 0, hope: 0, training: 0,page: 0, course: 0, post: 0, story: 0, app: 0, site: 0},
	resources2: []
});
