// react
import React from "react";
import {Table} from "react-bootstrap";
import {FaChartBar, FaUncharted} from "react-icons/fa";

// local
import {KeyValuePair} from "../types";

/**
 * SummaryStats
 */

export let summaryStats = [
	{ id: "utilization", name: 'Care Center Utilization', value: 0},
	{ id: "census", name: 'Number of Employees', value: 0},
	{ id: "hope", name: 'Hope & Norm Opt-Ins', value: 0},
	{ id: "training", name: 'Live Training Engagements', value: 0},
	{ id: "page", name: 'Site Visits', value: 0},
	{ id: "app", name: 'App Downloads', value: 0},
	{ id: "course", name: 'Courses Viewed', value: 0},
	{ id: "post", name: 'Posts Viewed', value: 0},
	{ id: "story", name: 'Stories Viewed', value: 0},
]

interface SummaryProps {
	summary: KeyValuePair[],
	chartId: string,
	chartName: string,
	isAdmin: boolean,
	onSelectChart: (chartId: string, chartName: string) => void
}
interface SummaryState {
}

export class SummaryStats extends React.Component<SummaryProps, SummaryState> {

	render() {
		// setup
		const {summary, chartId, onSelectChart, isAdmin} = this.props;

		// Construct the list.
		const body = summary.map(item => {
			// special handling
			let value;
			if (item.id === 'utilization') {
				value = (Math.ceil(item.value * 10) / 10)+'%'; // percentage rather than raw number
			} else {
				value = item.value.toLocaleString('en-US');
			}

			let chart = isAdmin
				? ((item.id === chartId)
					? <td className="selectable"><FaChartBar size={20}/></td>
					: <td className="selectable" onClick={() => onSelectChart(item.id, item.name)}><FaUncharted color={'#AAA'}/></td>)
				: null;

			// render
			return (<tr id={item.id} key={'summary'+item.id} >
				<td className="description">{item.name}</td>
				<td className="value">{value}</td>
				{chart}
			</tr>);
		});

		// render
		return (
			<div id="SummaryStats" className="section">
				<h4 className="title">YTD Summary Information</h4>
				<Table id="SummaryStats-Table" striped bordered>
					<tbody>{body}</tbody>
				</Table>
			</div>
		);
	}

}

