// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {Resource} from "../types";

/**
 * Course Views
 */

interface ViewsProps {
	views: Resource[],
}
interface ViewsState {
}

export class CourseViews extends React.Component<ViewsProps, ViewsState> {

	render() {
		// setup
		let total = 0;
		const {views} = this.props;

		// Construct the list.
		let body = views.length ?
			views.map(item => {
				total += item.engagements;
				return (<tr key={'course-views'+total}>
					<td className="description">{item.activity_title}</td>
					<td className="value">{item.engagements}</td>
				</tr>);
			}) :
			<tr id={'empty'} key={'empty'}>
				<td className="description warning" colSpan={2}>No Activity</td>
			</tr>;

		// render
		return (
			<div id="CourseViews" key="CourseViews" className="section">
				<h4 className="title">Courses Viewed</h4>
				<Table id="CourseViews-Table" key="CourseViews-Table" striped bordered>
					<tbody>{body}</tbody>
					<tfoot><tr>
						<td className="description">total</td><td className="value">{total}</td>
					</tr></tfoot>
				</Table>
			</div>
		);
	}

}

