// react
import React from "react";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip} from "recharts";

// local
import {KeyValuePair} from "../types";
import {TOOLTIP_STYLE} from "../types";

/**
 * Annual Stats
 */

interface StatsProps {
	chartName: string,
	totals1: number[],
	totals2: number[],
	ctlMonths: string[]
}
interface StatsState {
}

export class CareUtilization extends React.Component<StatsProps, StatsState> {

	tooltip = ({ active, payload }: any) => {
		// render
		const year2 = (new Date()).getFullYear();
		const year1 = year2 - 1;
		if (active && payload && payload.length) {
			return (
				<div className="tip-custom">
					<div className="tip-title">{payload[0].payload.name}</div>
					<div className="tip-this-year"> {`${year1.toString()}: ${payload[0].value.toFixed(1)}%`}</div>
					<div className="tip-last-year"> {`${year2.toString()}: ${payload[1].value.toFixed(1)}%`}</div>
				</div>
			);
		}
		return null;
	};

	yAxis = (tickItem: any) => {
		return tickItem.toString()+'%';
	};

	render() {
		// Construct the list.
		const {chartName, totals1, totals2, ctlMonths} = this.props;
		const year2 = (new Date()).getFullYear();
		const year1 = year2 - 1;

		const stats = [];
		for (let i = 0; i < 12; i++) {
			stats.push({name: ctlMonths[i], year1: totals1[i] * 12, year2: totals2[i] * 12});
		}

		// render
		return (
			<div id="AnnualStats" className="section">
				<h4 className="title">{chartName}</h4>
				<ResponsiveContainer width="100%" height={300}>
					<BarChart width={200} height={100} data={stats}>
						<CartesianGrid strokeDasharray="3 3" vertical={false}/>
						<Bar dataKey={'year1'} name={year1.toString()} fill="rgba(63, 135, 102, 0.2)"/>
						<Bar dataKey={'year2'} name={year2.toString()} fill="#198754"/>
						<YAxis
							label={{ value: 'Annualized %', angle: -90, position: 'insideLeft' }}
							tickFormatter={this.yAxis}
						/>
						<Legend />
						<XAxis
							dataKey="name"
							fontFamily="sans-serif"
							label={{ value: ''}}
							dy='25'
						/>
						<Tooltip content={this.tooltip} wrapperStyle={TOOLTIP_STYLE} cursor={{fill: '#3880ff54'}}/>
					</BarChart>
				</ResponsiveContainer>
			</div>
		);
	}

}

