// react
import React from "react";
import {inject, observer} from "mobx-react";
import {AiOutlineReload} from "react-icons/ai";
import {Button, Dropdown} from "react-bootstrap";
import {NavigateFunction} from "react-router/lib/hooks";

// local
import {
	FreshdeskEngagements,
	Footer,
	Header,
	Loading,
	PopupMessage
} from "../components";
import {
	FreshdeskCompany,
	FreshdeskTicket,
	PageProps,
	withNavigation,
	getDaysInMonth,
	FRESHDESK_PAGE_SIZE, API_LCT_KEY, webservice
} from "../types";
import {frApi} from "../App";

/**
 * Support Header
 */

interface SubheaderProps {
	navigate: NavigateFunction,
	date: Date
	onChangeDate: (year: number, month: number) => void,
	onRefreshList: () => void
}

const Subheader = (props: SubheaderProps) => {

	// setup
	const {date, onRefreshList} = props;
	const options:Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };
	let startDate = new Date(date.getFullYear(), 0, 1);
	const endDate = new Date();

	const dateId = date.toLocaleString('en-US', options);

	const eDates = [];
	while (startDate < endDate) {
		// setup
		const id = startDate.getFullYear() + '-' + startDate.getMonth();
		const name = startDate.toLocaleString('en-US', options);

		// render
		eDates.push(
			<Dropdown.Item id={id} key={id} eventKey={id}>
				{name}
			</Dropdown.Item>
		);

		// Increment the date.
		if (startDate.getMonth() === 11) {
			startDate = new Date(startDate.getFullYear() + 1, 0, 1);
		} else {
			startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
		}
	}

	function onChangeDate(eventKey: any) {
		// setup
		const date = eventKey.split('-');

		// Update the settings.
		props.onChangeDate(parseInt(date[0]), parseInt(date[1]));
	}

	function onReports() {
		// setup
		const {navigate} = props;

		// Go to the engagements page.
		navigate("/reports");
	}

	return (
		<div className="subheader">

			<Dropdown onSelect={onChangeDate}>
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					{dateId}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{eDates}
				</Dropdown.Menu>
			</Dropdown>

			<div className="navigate-sub">
				<Button variant="forgot" size="sm" onClick={onReports}>
					Back to Reporting
				</Button>
			</div>
			<Button variant="forgot" className="reload" size="sm" onClick={onRefreshList}>
				<AiOutlineReload size={20} color={'#9b61ff'} />
			</Button>

		</div>
	);
}

/**
 * EngagementsPage
 */

interface EngagementsProps extends PageProps {
}
interface EngagementsState {
	startDate: Date,
	endDate: Date,
	// companies
	companiesLoaded: boolean,
	companies: FreshdeskCompany[],
	// tickets
	ticketsLoaded: boolean,
	tickets: FreshdeskTicket[],
	ticketPage: number,
	ticketCount: number,
	// export
	exportTitle: string | null,
	exportMessage: string | null
}

@inject("user")
@inject("client")
@observer
class EngagementsPageComponent extends React.Component<EngagementsProps, EngagementsState> {

	constructor(props: EngagementsProps) {
		super(props);

		// Determine the default start/end date (this month).
		const date = new Date();
		const year = date.getFullYear();
		const month = date.getMonth();
		const startDate = new Date(year, month, 1, 0, 0, 0);
		const endDate = new Date(year, month, getDaysInMonth(month, year), 23, 59, 59);

		const options:Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };
		const exportTitle = 'Export Data: '+startDate.toLocaleString('en-US', options);

		this.state = {
			startDate,
			endDate,
			ticketsLoaded: false,
			tickets: [],
			companiesLoaded: false,
			companies: [],
			ticketPage: 0,
			ticketCount: 0,
			exportTitle,
			exportMessage: null
		}

		// Register the callbacks.
		this.onChangeDate = this.onChangeDate.bind(this);
		this.onDismissPopup = this.onDismissPopup.bind(this);
		this.onExportList = this.onExportList.bind(this);
		this.onGoBack = this.onGoBack.bind(this);
		this.onLogout = this.onLogout.bind(this);
		this.onPagePrev = this.onPagePrev.bind(this);
		this.onPageNext = this.onPageNext.bind(this);
		this.onRefreshList = this.onRefreshList.bind(this);
	}

	componentDidMount = async () => {
		// setup
		const {companies, startDate, endDate} = this.state;

		// freshdesk
		this.fetchCompanies(companies,1);
		this.fetchTickets(startDate, endDate, 0, []);
	}

	fetchCompanies(companies: FreshdeskCompany[], page: number) {
		// See: https://developers.freshdesk.com/api/
		const that = this;

		const params = {page};
		frApi.listAllCompanies(params, function (err: any, data: any, extra: any) {
			for (let i = 0; i < data.length; i++) {
				companies.push({
					id: data[i].id,
					name: data[i].name
				});
			}

			// Kludge to get all the companies in this loop.
			if (data.length >= FRESHDESK_PAGE_SIZE) {
				that.fetchCompanies(companies, page+1);
			} else {
				that.setState({companies, companiesLoaded: true});
			}
		});
	}

	fetchTickets(startDate: Date, endDate: Date, ticketPage: number, tickets1: FreshdeskTicket[]) {
		// setup
		const that = this;
		const start = (startDate.toISOString().split('T'))[0];
		const end = (endDate.toISOString().split('T'))[0];
		const query = `created_at:> '${start}' AND created_at:< '${end}'`;

		// Get the details for the specified date range.
		frApi.filterTickets(query, ticketPage + 1, function (err: any, data: any, extra: any) {
			// Create the tickets.
			const tickets2 = data.results.map((item: any) => {
				const extended = item.custom_fields;
				return {
					id: item.id,
					type: item.type,
					category: extended.cf_employee_or_family_member,
					description: item.subject,
					tags: item.tags,
					status: item.status,
					priority: item.priority,
					company_id: item.company_id,
					created: new Date(item.created_at),
					updated: new Date(item.updated_at)
				}
			});
			const tickets = tickets1.concat(tickets2);
			const ticketLast = ((ticketPage * FRESHDESK_PAGE_SIZE + tickets2.length) >= data.total);
			if (ticketLast) {
				that.setState({tickets, ticketCount: tickets.length, ticketsLoaded: true});
			} else {
				that.fetchTickets(startDate, endDate, ticketPage + 1, tickets.concat(tickets1));
			}
		});
	}

	onChangeDate(year: number, month: number) {
		// Determine the new start/end dates.
		const startDate = new Date(year, month, 1, 0, 0, 0);
		const endDate = new Date(year, month, getDaysInMonth(month, year), 23, 59, 59);

		const options:Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };
		const exportTitle = 'Export Data: '+startDate.toLocaleString('en-US', options);

		// Retrieve the new information.
		this.setState({startDate, endDate, exportTitle, ticketsLoaded: false, ticketPage: 0, ticketCount: 0});
		this.fetchTickets(startDate, endDate, 0, []);
	}

	onDismissPopup() {
		// setup
		this.setState({exportMessage: null});
	}

	onGoBack() {
		// setup
		const {navigate} = this.props;
		navigate('/reports');
	}

	onExportList = async () => {
		// setup
		this.setState({exportMessage: "Loading"});

		const {tickets, startDate, companies} = this.state;
		const year = startDate.getFullYear();
		const month = startDate.getMonth() + 1;

		const params = new FormData();
		params.append('wstoken', API_LCT_KEY);
		params.append('wsfunction', 'mdl_add_activities');
		params.append('moodlewsrestformat', 'json');

		params.append('source', 'freshdesk');
		params.append('year', year.toString());
		params.append('month', month.toString());

		for (let i = 0; i < tickets.length; i++) {
			// Normalize the data.
			const item = tickets[i];
			const company = companies.find(c => c.id === item.company_id);

			// Add to the post.
			params.append('activities[]', JSON.stringify({
				company_id: company ? company.name : null,
				activity_id: item.id,
				activity_type: 'care',
				activity_category: item.category,
				activity_name: item.type,
				activity_title: item.type,
				engagements: 1,
				time: 60
			}));
		}

		// Make the request.
		const results = await webservice.post('/webservice/rest/server.php', params);
		const data = results.data;
		const exportMessage = (data && data.success) ?
			`Completed exporting ${data.count} tickets.` :
			`Error exporting ${tickets.length} tickets.`;
		this.setState({exportMessage});
	}

	onLogout() {
		// setup
		const {navigate, user} = this.props;

		// Logout and go to the login page.
		user.logout();
		navigate("/login");
	}

	onPageNext() {
		// setup
		const {ticketPage, startDate, endDate} = this.state;

		// Go to the next page.
		this.setState({ticketPage: ticketPage + 1});
	}

	onPagePrev() {
		// setup
		const {ticketPage, startDate, endDate} = this.state;

		// Go to the previous page.
		this.setState({ticketPage: ticketPage - 1});
	}

	onRefreshList() {
		// setup
		const {companies, startDate, endDate} = this.state;

		// freshdesk
		this.fetchTickets(startDate, endDate, 0, []);
		this.setState({ticketPage: 0});
	}

	render() {
		// setup
		const {navigate, client} = this.props;
		const {startDate, exportTitle, exportMessage} = this.state;

		// render
		return (
			<div className="page">
				<Header client={client}>
					<Button id="Logout" variant="primary" onClick={this.onLogout}>Logout</Button>
				</Header>

				<Subheader
					navigate={navigate}
					date={startDate}
					onChangeDate={this.onChangeDate}
					onRefreshList={this.onRefreshList}
				/>

				<div className="body">
					<div className='scroll-y'>
						{this.renderEngagements()}
						<hr/>
					</div>
				</div>

				<PopupMessage
					show={!!exportMessage}
					title={exportTitle}
					body={exportMessage}
					onClose={this.onDismissPopup}
				/>

				<Footer/>
			</div>
		);
	}

	renderEngagements() {
		// setup
		const {
			companies, tickets, companiesLoaded,
			ticketsLoaded, ticketPage, ticketCount,
			exportTitle
		} = this.state;

		// render
		if (!companiesLoaded || !ticketsLoaded) {
			return <Loading/>
		}
		return (
			<details open>
				<summary>Care Center Engagements</summary>
				<Button variant="forgot" className="export" size="sm" onClick={this.onExportList}>
					{exportTitle}
				</Button>

				<div className="scroll-y">
					<FreshdeskEngagements
						companies={companies}
						tickets={tickets}
						ticketPage={ticketPage}
						ticketCount={ticketCount}
						pagePrev={this.onPagePrev}
						pageNext={this.onPageNext}
					/>
				</div>

			</details>
		);
	}

}

export const EngagementsPage = withNavigation(EngagementsPageComponent);
