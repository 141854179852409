// react
import React from "react";
import {Table} from "react-bootstrap";

// local
import {Resource} from "../types";

/**
 * Page Visits
 */

interface VisitsProps {
	visits: Resource[],
}

interface VisitsState {
}

export class SiteVisits extends React.Component<VisitsProps, VisitsState> {

	render() {
		// setup
		let total = 0;
		const {visits} = this.props;

		// Construct the list.
		const body = visits.length ?
			visits.map(item => {
				total += item.engagements;
				return (<tr key={'page'+total}>
					<td className="description">{item.activity_title}</td>
					<td className="value">{item.engagements}</td>
				</tr>);
			}) :
			<tr id={'empty'} key={'empty'}>
				<td className="description warning" colSpan={2}>No Activity</td>
			</tr>;

		// render
		return (
			<div id="SiteVisits" key="SiteVisits" className="section">
				<h4 className="title">Site Visits</h4>
				<Table id="SiteVisits-Table" key="SiteVisits-Table" striped bordered>
					<tbody>{body}</tbody>
					<tfoot><tr><td className="description">total</td><td className="value">{total}</td></tr></tfoot>
				</Table>
			</div>
		);
	}

}

