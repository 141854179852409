// react
import React from "react";
import {SummaryChart} from "./SummaryChart";

// local
import {KeyValuePair} from "../types";

/**
 * Insights
 */

interface InsightsProps {
	clientName: string | null,
	clientReason: KeyValuePair[],
	clientWho: KeyValuePair[],
	allReason: KeyValuePair[],
	allWho: KeyValuePair[],
}

interface InsightsState {
}

export class CompanyInsights extends React.Component<InsightsProps, InsightsState> {

	render() {
		// setup
		const {clientName, clientReason, clientWho, allReason, allWho} = this.props;

		// render
		return (
			<div className="row">
				{clientReason.length
					? <SummaryChart title={`Reason for Reaching In (${clientName})`} data={clientReason}/>
					: <div className="column warning">Reason for Reaching In (no client activity)</div>
				}
				{clientReason.length
					? <SummaryChart title={`Who Reached In (${clientName})`} data={clientWho}/>
					: <div className="column warning">Who Reached In (no client activity)</div>
				}
				<SummaryChart title="Reason for Reaching In (All Care Centers)" data={allReason}/>
				<SummaryChart title="Who Reached In (All Care Centers)" data={allWho}/>
			</div>
		);
	}

}

