const __DEV__ = false; // TODO - TEMPORARY
export const API_URL = (__DEV__) ? "http://rpaulsen.iomad.com" : "https://care.blunovus.com";
export const API_LCT_KEY = "a192ef97a7a9fe68fbf02de8bdb898cf"
export const API_MDL_KEY = "4e3c0f30ea835e2644cd4e63480b8232"
export const API_IOMAD_KEY = "8c7a007d0667223281b660dc5e470baa"
export const ROOT_COMPANY_ID = 2;

/* For recharts */
export const TOOLTIP_STYLE = {padding: '2px 10px', backgroundColor: '#fff', borderRadius: '8px', border: '1px solid #8b61ff'};
export const LEGEND_STYLE = {display: "inline-block"};

/* For Google */
//const GOOGLE_TRACKING_ID = "UA-XXXXX-X"; // OUR_TRACKING_ID

/* For Freshdesk */
export const FRESHDESK_PAGE_SIZE = 30;

export function getDaysInMonth(m: number, y: number) {
	return (m===2) ? y & 3 || !(y%25) && y & 15 ? 28 : 29 : 30 + (m+(m>>3)&1);
}

export function fridaysInMonth(m: number, y: number) {
	const days = new Date(y, m,0).getDate();
	let fridays = [ 6 - (new Date( m +'/01/'+ y ).getDay()) ];
	for (let i = fridays[0] + 7; i < days; i += 7) {
		fridays.push( i );
	}
	return fridays.length;
}
