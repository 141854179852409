// react
import React from "react";
import {useNavigate, useParams} from "react-router-dom";

/**
 * withNavigation - for use with routing components.
 * @param Component
 */
export function withNavigation(Component: React.ComponentType<any>) {

	const WithNavigation = (props: any) => {
		const navigate = useNavigate();
		const params = useParams();
		return <Component {...props} navigate={navigate} params={params} />;
	}

	return WithNavigation;
}
